import { getCommonParamsItemLabel } from '@/utils/common'
import store from '@/store'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'param.template-name',
      width: 180
    },
    {
      prop: 'appName',
      label: 'param.template-app-name',
      width: 180
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'description',
      label: 'general.description',
      width: 200
    },
    {
      prop: 'status',
      label: 'common.app-status',
      width: 120,
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },
    {
      label: 'common.app-operation',
      width: '480',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
